<template>
  <div class="form">
    <ValidationObserver v-if="!isSignUpSuccessModal" v-slot="{ handleSubmit }">
      <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleRegister)">
        <ValidationProvider v-slot="{ errors }" rules="required|email">
          <BaseInput
            v-model="form.email"
            v-e2e="'login-modal-email'"
            :valid="!errors[0]"
            :error-message="errors[0]"
            name="email"
            label="Your Email"
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <BaseInput
            v-model="form.firstName"
            v-e2e="'login-modal-firstName'"
            :valid="!errors[0]"
            :error-message="errors[0]"
            name="first-name"
            label="First Name"
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <BaseInput
            v-model="form.lastName"
            v-e2e="'login-modal-lastName'"
            :valid="!errors[0]"
            :error-message="errors[0]"
            name="last-name"
            label="Last Name"
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required|min:5">
          <BaseInput
            v-model="form.password"
            v-e2e="'login-modal-password'"
            :valid="!errors[0]"
            :error-message="errors[0]"
            name="password"
            label="Password"
            type="password"
            class="form__element"
          />
        </ValidationProvider>

        <div v-if="error.register" class="form-error">
          {{ error.register }}
        </div>
        <BaseButton type="submit" class="sf-button--full-width form__button" :disabled="loading">
          <BaseLoader :class="{ loader: loading || isLoginLoading }" :loading="loading">
            <div>{{ $t('Create an account') }}</div>
          </BaseLoader>
        </BaseButton>
      </form>
    </ValidationObserver>
    <div v-else class="signup-notification">
      <h2 class="signup-notification-title">
        {{ notification.title }}
      </h2>
      <p class="signup-notification-message">
        {{ notification.message }}
      </p>
      <p v-if="notification.loginError" class="signup-notification-message form-error">
        {{ notification.loginError }}
      </p>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, useRoute } from '@nuxtjs/composition-api';
import { BaseButton, BaseInput, BaseLoader } from '@unified-commerce/gpc-ui-library';
import { useCart, useUser } from '@unified-commerce/gpc-vue-storefront-shopify';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, min, required } from 'vee-validate/dist/rules';

import { loginUser } from '../helpers/login/loginUser';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'Password is too short (at least 5 characters required)',
});

export default defineComponent({
  name: 'SignupForm',
  components: {
    BaseInput,
    BaseButton,
    BaseLoader,
    ValidationProvider,
    ValidationObserver,
  },
  setup(_props, context) {
    const cookies = context.root.$cookies;
    const form = ref({});
    const route = useRoute();
    const isLoginLoading = ref(false);
    const userEmail = ref('');
    const { cart } = useCart();
    const isSignUpSuccessModal = ref(false);
    const notification = ref({
      title: '',
      message: '',
      loginError: '',
    });

    const { user, register, login, load: loadUser, loading, error: userError } = useUser();

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    const handleRegister = async () => {
      resetErrorValues();
      await register({ user: form.value });

      const hasUserRegistrationErrors = userError.value.register || user.value.error;
      if (hasUserRegistrationErrors && userError.value.register) {
        error.register =
          "Sorry we couldn't process your request at this time. Please try again later";
        return;
      } else if (hasUserRegistrationErrors && user.value.error) {
        error.register = user.value.error;
        return;
      }

      await loadUser();

      notification.value.title = 'Success!';
      notification.value.message =
        'You have successfully signed up, welcome to the 4WD247 crew. We have sent you a welcome email';
      isSignUpSuccessModal.value = true;

      context.emit('register-success');
      await userLogin();
    };

    const userLogin = async () => {
      resetErrorValues();

      await loginUser(
        isLoginLoading,
        route,
        login,
        ref({ username: form.value.email, password: form.value.password }),
        cookies,
        user,
        userError,
        error,
        cart,
        context.root.$config.appKey,
      );

      if (error.login) {
        notification.value.loginError =
          "Sorry we couldn't process your login request at this time. Please try again later";
      } else if (user.value.multipass) {
        window.location.href = user.value.multipass.url;
      } else {
        isLoginLoading.value = false;
        // in case of signup page, this redirects to home and in case of popup, it toggles the modal
        context.emit('internal-login-success');
      }
    };

    return {
      form,
      error,
      userError,
      loading,
      isLoginLoading,
      handleRegister,
      userLogin,
      userEmail,
      isSignUpSuccessModal,
      notification,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  --modal-index: var(--modal-z-index);
  --overlay-z-index: var(--modal-z-index);
}

.signup-notification {
  padding: var(--spacer-xl) var(--spacer-3base);

  &-title {
    font-size: var(--signup-notification-page-title, var(--font-size--xl));
    text-align: center;
  }

  &-message {
    font-size: var(--signup-notification-page-message, var(--font-size--lg));
    text-align: center;
    color: var(--c-gray);
  }
}

.form {
  margin-top: var(--spacer-sm);
  &__element {
    margin: 0 0 var(--spacer-xl) 0;
  }

  &-error {
    color: var(--c-primary);
    margin-bottom: var(--spacer-xs);
  }
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
  font: var(--font-weight--light) var(--font-size--base) / 1.6 var(--font-family--secondary);
  & > * {
    margin: 0 0 0 var(--spacer-xs);
  }
}
.action {
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
}
.checkbox {
  margin-bottom: var(--spacer-2xl);
}
.bottom {
  text-align: center;
  margin-bottom: var(--spacer-lg);
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight--semibold);
  font-family: var(--font-family--secondary);
  &__paragraph {
    color: var(--c-primary);
    margin: 0 0 var(--spacer-base) 0;
    @include lg-devices-min {
      margin: 0;
    }
  }
}
.thank-you {
  &__paragraph {
    &--bold {
      font-weight: var(--font-weight--semibold);
    }
  }
}
</style>
