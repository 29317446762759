<template>
  <div v-if="!hasForgottenPassword">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="form" @submit.prevent="handleSubmit(handleLogin)">
        <ValidationProvider v-slot="{ errors }" rules="required|email">
          <BaseInput
            v-model="form.username"
            v-e2e="'login-modal-email'"
            :valid="!errors[0]"
            :error-message="errors[0]"
            name="email"
            label="Your Email"
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <BaseInput
            v-model="form.password"
            v-e2e="'login-modal-password'"
            :valid="!errors[0]"
            :error-message="errors[0]"
            name="password"
            label="Password"
            type="password"
            class="form__element"
          />
        </ValidationProvider>
        <BaseCheckbox
          v-model="rememberMe"
          v-e2e="'login-modal-remember-me'"
          name="remember-me"
          label="Remember me"
          class="form__element checkbox"
        />
        <div v-if="error.login" class="form-error">
          {{ error.login }}
        </div>
        <BaseButton
          v-e2e="'login-modal-submit'"
          type="submit"
          class="sf-button--full-width form__button"
          :disabled="isLoading"
        >
          <BaseLoader :class="{ loader: isLoading }" :loading="isLoading">
            <div>{{ $t('Login') }}</div>
          </BaseLoader>
        </BaseButton>
      </form>
    </ValidationObserver>
    <div class="action">
      <BaseButton class="sf-button--text" @click="showForgotPasswordForm">
        {{ $t('Forgotten password?') }}
      </BaseButton>
    </div>
  </div>
  <div v-else>
    <ForgotPasswordForm />

    <div class="action">
      <BaseButton class="sf-button--text" @click="showLoginForm">
        {{ $t('Login') }}
      </BaseButton>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref, useRoute } from '@nuxtjs/composition-api';
import { BaseButton, BaseCheckbox, BaseInput, BaseLoader } from '@unified-commerce/gpc-ui-library';
import { useCart, useUser } from '@unified-commerce/gpc-vue-storefront-shopify';
import dayjs from 'dayjs';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

import ForgotPasswordForm from '~/components/ForgotPasswordForm.vue';
import { useUiHelpers } from '~/composables';

import { loginUser } from '../helpers/login/loginUser';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default {
  name: 'LoginForm',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseLoader,
    ValidationProvider,
    ValidationObserver,
    ForgotPasswordForm,
  },
  setup(_props, context) {
    const form = ref({});
    const route = useRoute();
    const isLoading = ref(false);
    const hasForgottenPassword = ref(false);
    const userEmail = ref('');
    const rememberMe = ref(false);
    const { user, login, error: userError } = useUser();
    const { cart } = useCart();
    const {
      IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM,
      IS_LOGIN_FROM_CHECKOUT_COOKIE,
      getQueryParam,
      getCookieName,
    } = useUiHelpers();

    const cookies = context.root.$cookies;

    const error = reactive({
      login: null,
      forgotPassword: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.forgotPassword = null;
    };

    const success = reactive({
      forgotPassword: null,
    });

    const resetSuccessValues = () => {
      success.forgotPassword = null;
    };

    const handleLogin = async () => {
      resetErrorValues();
      resetSuccessValues();

      const result = await loginUser(
        isLoading,
        route,
        login,
        form,
        cookies,
        user,
        userError,
        error,
        cart,
        context.root.$config.appKey,
      );

      if (!result) {
        return;
      }

      if (user.value.multipass) {
        window.location.href = user.value.multipass.url;
      } else {
        isLoading.value = false;
        context.emit('success');
      }
    };

    const showLoginForm = () => {
      hasForgottenPassword.value = false;
    };

    const showForgotPasswordForm = () => {
      hasForgottenPassword.value = true;
    };

    onMounted(() => {
      const isLoginFromCheckout = getQueryParam(IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM);

      if (isLoginFromCheckout) {
        cookies.set(
          getCookieName(context.root.$config.appKey, IS_LOGIN_FROM_CHECKOUT_COOKIE),
          'yes',
          {
            path: '/',
            expires: dayjs().add(2, 'minutes').toDate(),
          },
        );
      }
    });

    return {
      form,
      error,
      userError,
      success,
      isLoading,
      rememberMe,
      handleLogin,
      userEmail,
      hasForgottenPassword,
      showLoginForm,
      showForgotPasswordForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal {
  --modal-index: var(--modal-z-index);
  --overlay-z-index: var(--modal-z-index);
}
.form {
  margin-top: var(--spacer-sm);
  margin-bottom: var(--spacer-sm);
  &__element {
    margin: 0 0 var(--spacer-xl) 0;
  }

  &-error {
    color: var(--c-primary);
    margin-bottom: var(--spacer-xs);
  }

  &-success {
    color: var(--c-gray-variant);
    margin-bottom: var(--spacer-xs);
  }
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
  font: var(--font-weight--light) var(--font-size--base) / 1.6 var(--font-family--secondary);
  & > * {
    margin: 0 0 0 var(--spacer-xs);
  }
}
.action {
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
}
.checkbox {
  margin-bottom: var(--spacer-2xl);
}
.bottom {
  text-align: center;
  margin-bottom: var(--spacer-lg);
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight--semibold);
  font-family: var(--font-family--secondary);
  &__paragraph {
    color: var(--c-primary);
    margin: 0 0 var(--spacer-base) 0;
    @include lg-devices-min {
      margin: 0;
    }
  }
}
.thank-you {
  &__paragraph {
    &--bold {
      font-weight: var(--font-weight--semibold);
    }
  }
}
</style>
